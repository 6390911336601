/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
html, body {
	font-size: 16px;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	outline: none;
	outline-style:none;
}
 
:focus {
	outline:none;
}

a {
  	text-decoration: none;
}

.MuiPaper-elevation2 {
	box-shadow: none!important; /*TO REMOVE MATERIAL-UI STYLING */
}

.MuiTableCell-paddingNone {
	padding: 0px 40px 0px 16px!important;
}

.MuiPaper-root {
	background-color: transparent!important; /*TO REMOVE MATERIAL-UI STYLING */
}

.MuiPaper-root.MuiPopover-paper{
	background-color: #ffffff!important; /*TO REMOVE MATERIAL-UI STYLING */
}


.MuiTableRow-root th {
	padding:0px 30px 0px 16px!important; /*TO REMOVE MATERIAL-UI STYLING */
}

.MuiTable-root {
	border-collapse: separate!important;/*TO REMOVE MATERIAL-UI STYLING */
    border-spacing: 0px 6px!important;/*TO REMOVE MATERIAL-UI STYLING */
}

.MuiTableCell-root {
	padding: 8px 40px 8px 16px;
}


#KitList .MuiPaper-root div {
	overflow: inherit!important; /*TO REMOVE MATERIAL-UI STYLING */
}

#KitList .MuiTableCell-root:last-child {
	padding-right: 8px!important; /*TO REMOVE MATERIAL-UI STYLING */
}

#Ritz-table .MuiTableCell-root {
	border-bottom: none!important; /*TO REMOVE MATERIAL-UI STYLING */
}

#Family-members .MuiTableCell-root {
	padding: 25px!important; /*TO REMOVE MATERIAL-UI STYLING */
}

.MuiCheckbox-colorSecondary.Mui-checked{
    color: #15994C !important
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1s infinite ease-in-out;
  animation: load7 1s infinite ease-in-out;
}
.loader {
  color: #227462;
  font-size: 10px;
  margin: 2% auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  margin-left: -1px;
}

.pagination>li.active>a {
  background-color: #81BE3B;
  border-color: #81BE3B;
  border-radius: 50px;
  color:  white;
  font-weight: 600;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
  background-color:  #30735f;
  border-radius: 50px;
  cursor: pointer;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
    padding: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: none!important;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  margin-right: 0;
  padding: 0px!important;
  border-top-right-radius: 4px;
  display: none!important;
}

/***************** PAGINATION 2 **********************/
.pagination2 > li {
  display: inline-block;
  padding-left: 0;
}
.pagination2 > li {
  list-style: none;
}
.pagination2 > li > a >div>span,
.pagination2 > li  {
  display: flex;
  align-items: center;
  color: #227462;
  position: relative;
  float: left;
  padding: 6px 4px;
  line-height: 1.42857143;
  text-decoration: none;
  /* margin-left: -1px; */
  margin: -2.7px 1px auto 1px;
  font-size: 14px;
}

.pagination2 > li > a >div> span:last-child{
  opacity: 0;
}
.pagination2 > li.active > a > div > span:last-child{
  opacity: 1;
}



.pagination2>li.active>a>div>span {
  color:  #227462;
  font-weight: 600;
  margin-top: -2.7px;
}



/* Style the active class (and buttons on mouse-over) */

.pagination2 > li > a{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1.5px solid #f9f9fb;
  border-radius: 50px;
}
.pagination2 > li.break-me > a{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1.5px solid #f9f9fb;
  border-radius: 50px;
  padding-top: 5px;
}

.pagination2 > li > a:hover {
  /* background-color:  #30735f; */
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 32px;
  height: 32px;
  border: 1.5px solid rgba(34, 116, 98, .5);
  border-radius: 50px;
  color: black;
  cursor: pointer;
  margin-top: -6px;
  margin-left: 0;
  margin-right: 0;
  transition: 0.5s;

}
.pagination2 > li > a >div:hover > span:last-child{
  opacity: 0;
}
.pagination2 > li:first-child > a,
.pagination2 > li:first-child > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 1.5px solid #227462;
  border-radius: 9999px;
  color: #227462;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 2px 10px 0;
  font-weight: 300;
  font-size: 40px;
  margin-top: 6px;
}
.pagination2 > li:last-child > a,
.pagination2 > li:last-child > span {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 28px;
  height: 28px; */
  width: 28px;
  height: 28px;
  margin-right: 0;
  border: 1.5px solid #227462;
  border-radius: 9999px;
  color: #227462;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 0 10px 2px;
  font-weight: 300;
  font-size: 40px;
  margin-top: 6px;
}

.pagination2 > li:first-child > a:hover,
.pagination2 > li:first-child > span:hover,
.pagination2 > li:last-child > a:hover,
.pagination2 > li:last-child > span:hover {
  background-color:  #30735f;
  border-radius: 50px;
  color: black;
  cursor: pointer;
  opacity: 0.8;
  margin-top: -4px;
  transition: 0.5s;
}

